<template>
  <v-card text-center>
    <div v-if="loaded">
      <div class="d-flex align-center justify-space-between pt-3 pl-6 pr-3">
        <div class="mt-6">
          <h3 class="title">
            {{ catalog.name + ( isLastQuestion ? ' quiz complete!' : '' ) }}
          </h3>
        </div>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="pa-6 pt-0">
        <div class="pt-3">
          <span v-if="isLastQuestion">Your result is: </span>
          <span v-if="!isLastQuestion">Question {{ questionNo }}: </span>
          <span :class="{'subtitle-1': isLastQuestion}">{{ currentQuestion.title }}</span>
          <div class="pt-3"  v-if="currentQuestion.image">
              <a :href="currentQuestion.link" v-if="currentQuestion.link" target="_blank">
                  <img :src="currentQuestion.image" alt="result diagram" width="500" />
              </a>
          </div>
          <div class="pt-3"  v-if="currentQuestion.image1">
              <a :href="currentQuestion.link1" v-if="currentQuestion.link1" target="_blank">            
                  <img :src="currentQuestion.image1" alt="second result diagram1" width="500" />
              </a>                  
          </div>
        </div>
        <div>
          <v-radio-group
            v-model="selectedOption"
            class="mt-3"
          >
            <div 
              v-for="o in currentQuestion.options"
              :key="o.key"
              class="py-2"
            >
              <v-radio
                :value="o"
                :label="o.label"
              />
            </div>
          </v-radio-group>
        </div>
        <div>
          <div class="d-flex justify-end">
            <v-btn
              v-if="!isFirstQuestion"
              text
              @click="previousQuestion"
            >
              {{ previousLabel }}
            </v-btn>
            <v-btn
              class="primary ml-3"
              @click="nextQuestion"
              :disabled="this.selectedOption == null && !isLastQuestion"
              width="120px"
            >
              {{ nextLabel }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
  import {mapGetters} from 'vuex';
  export default {
    name: 'Quiz',
    props: {
      quizId: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        questionNo: 1,
        catalog: null,
        selectedOption: null
      };
    },
    computed: {
      ...mapGetters({
        catalogs: 'catalogs',
        quiz: 'quiz',
        currentQuestion: 'currentQuestion',
        prevResponses: 'responses'
      }),
      loaded() {
        return this.quiz && this.quiz.key != null && this.catalog && this.catalog.id != null;
      },
      isFirstQuestion() {
        return this.currentQuestion === this.quiz;
      },
      isLastQuestion() {
        return !this.currentQuestion.children;
      },
      nextLabel() {
        return this.isLastQuestion ? 'Done' : 'Next';
      },
      previousLabel() {
        return this.isLastQuestion ? 'Restart' : 'Back';
      },
      questionTitle() {
        return this.isLastQuestion ? "Quiz Complete" : `Question ${this.questionNo}`;
      }
    },
    watch: {
      catalogs() {
        this.initCatalog();
      }
    },
    created() {
      this.initCatalog();
      this.$store.dispatch('fetchQuiz', this.quizId);
    },
    methods: {
      initCatalog() {
        if (this.catalogs && this.catalogs.length > 0) {
          this.catalog = this.catalogs.find(c => c.id === this.quizId);
        }
      },
      nextQuestion() {
        if (this.isLastQuestion) {
          this.$emit('close');
          return;
        }

        this.$store.dispatch('nextQuestion', this.selectedOption)
        .then(() => {
          this.questionNo++;
          const prevResponse = this.prevResponses.find(r => r.key === this.currentQuestion.key);
          this.selectedOption = prevResponse ? prevResponse.option : null;
        });
      },
      previousQuestion() {
        if (this.isLastQuestion) {
          // restart quiz
          this.questionNo = 1;
          this.selectedOption = null;
          this.$store.commit('initQuiz', this.quiz);
          return;
        }

        this.$store.dispatch('previousQuestion')
        .then(() => {
          this.questionNo--;
          const prevResponse = this.prevResponses.find(r => r.key === this.currentQuestion.key);
          this.selectedOption = prevResponse.option;
        });
      }
    }
  };
</script>

<style scoped lang="scss">
</style>