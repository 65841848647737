<template>
  <v-container text-center>
    <div>
      <span>Nothing to see here</span>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'Settings',
  };
</script>
