
import axios from 'axios';
import { getApiUrl, getConfig } from '../utils';

const fetchCatalogs = () => {
  const url = `${getApiUrl()}/catalog`;
  const apiKey = `${getConfig('VUE_APP_API_KEY')}`

  return axios.get(url, {
    headers: {
      'x-apikey': apiKey
    }
  })
  .then(response => {
    // eslint-disable-next-line
    console.log("Catalog data", response);
    return response.data.data;
  })
  .catch(err => {
    // eslint-disable-next-line
    console.error(err);
    throw err;
  });
};

export default { fetchCatalogs }
