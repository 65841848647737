<template>
  <v-app>
    <NavigationDrawer
      :items="items"
    />

    <AppBar
      color="primary"
      app-name="my-nsw-gov"
      title="Decision Tree Quiz"
      :search-filter-mutator="false"
      :show-full-screen="false"
      :show-nav-icon="true"
    >
      <template slot="appIcon">
        <img
          src="./assets/my-nsw-gov.svg"
          alt="nsw gov logo"
        >
      </template>

      <template slot="profile">
        <Profile
          :given-name="profile.given_name"
          :surname="profile.family_name"
          job-title=" "
          :update-profile="false"
          :change-password="false"
          :update-secret-question="false"
          :portal-settings="false"
          :logout="true"
          :logout-handler="logout"
        />
      </template>
    </AppBar>

    <v-main>
      <v-container
        fluid
        v-if="oauthLoaded"
      >
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-container>
    </v-main>

    <!-- app wide components -->
    <BlockUI v-if="isLoading" />
  </v-app>
</template>

<script>
  import { AppBar, NavigationDrawer, Profile, BlockUI } from "@nswdoe/doe-ui-core";
  import { mapGetters } from "vuex";

  export default {
    name: "App",
    components: {
      BlockUI,
      AppBar,
      NavigationDrawer,
      Profile
    },
    computed: {
      ...mapGetters({
        isLoading: 'isLoading'
      }),
      profile() {
        return this.$OAuth.profile || {};
      },
      oauthLoaded() {
        return this.$OAuth.loaded;
      }
    },
    data() {
      return {
        items: [
          {
            title: "Home",
            icon: "mdi-home-outline",
            route: "/"
          },
          {
            title: "Settings",
            icon: "settings",
            iconOutlined: true,
            route: "/settings"
          }
        ]
      }
    },
    methods: {
      logout() {
        this.$OAuth.logout();
        return false;
      }
    }
  };
</script>

<style lang="scss">
@import './scss/ads.scss';

</style>
