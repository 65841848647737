import Vue from "vue"
import Vuex from "vuex"

import catalogsModule from "./modules/catalogs";
import quizModule from "./modules/quiz";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    catalogsModule,
    quizModule
  },
  state: {
    isLoading: false,
    loadingCount: 0,
  },
  getters: {
    isLoading: state => state.isLoading
  },
  mutations: {
    setIsLoading(state, isLoading) {
      if (isLoading) {
        state.loadingCount++;
      } else {
        state.loadingCount--;
      }

      state.isLoading = state.loadingCount > 0;
    }
  },
  actions: {
  }
})
