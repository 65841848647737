<template>
  <v-container text-center>
    <div class="d-flex flex-wrap justify-center">
      <v-card
        v-for="(c, i) in catalogs"
        :key="i"
        class="catalog-card ma-4 d-flex flex-column justify-space-between"
        elevation="2"
        @click="openQuiz(c.id)"
      >
        <div
          class="catalog-card-icon"
          v-html="c.icon"
        /> 
        <div class="catalog-card-footer pa-3">
          <span class="body-1">{{ c.name }}</span>
        </div>
      </v-card>

      <v-dialog
        v-if="showQuiz"
        v-model="showQuiz"
        persistent
        max-width="600px"
        overlay-opacity="0.75"
      >
        <Quiz 
          :quiz-id="this.selectedQuizId"
          @close="closeQuiz"
        />
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
  import {mapGetters} from 'vuex';
  import Quiz from './Quiz';

  export default {
    name: 'Home',
    components: {
      Quiz
    },
    data() {
      return {
        selectedQuizId: this.getQueryParam('quizId')
      }
    },
    computed: {
      ...mapGetters({
        catalogs: 'catalogs'
      }),
      showQuiz() {
        return this.selectedQuizId != null && this.selectedQuizId !== '';
      }
    },
    created() {
      if (!this.catalogs || !this.catalogs.length) {
        this.$store.dispatch('fetchCatalogs');
      }
    },
    methods: {
      getQueryParam(name) {      
        // get a query param value case-insensitively
        for (const p in this.$route.query) {
          if (p.toLowerCase() === name.toLowerCase()) {
            return this.$route.query[p];
          }
        }
        return '';
      },
      openQuiz(id) {
        this.selectedQuizId = id;
        this.$router.push(`?quizId=${encodeURIComponent(id)}`);
      },
      closeQuiz() {
        this.selectedQuizId = null;
        this.$router.push("/");
      }
    }
  };
</script>

<style scoped lang="scss">
  .catalog-card {
    width: 240px;
    height: 144px;
  }
  ::v-deep .catalog-card-icon {
    height: 100%;
    svg.svg-icon {
      width: 60px;
      height: 100%;
      path {
        fill: $ads-blue-1;
      }
    }
  }

  .catalog-card-footer {
    background-color: $ads-blue-1;
    color: $ads-white;
  }
</style>