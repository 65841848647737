import catalogsApi from 'api/catalogs';

const catalogsModule = {
  state: {
    catalogs: []
  },
  getters: {
    catalogs: state => state.catalogs
  },
  mutations: {
    setCatalogs(state, catalogs) {
      state.catalogs = catalogs;
    }
  },
  actions: {
    async fetchCatalogs({commit}) {
      commit('setIsLoading', true);

      return await catalogsApi.fetchCatalogs()
        .then((response) => {
          commit('setCatalogs', response);
          commit('setIsLoading', false);
        })
        .catch(() => {
          commit('setIsLoading', false);
        });
    },
  }
};

export default catalogsModule;