import 'babel-polyfill';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import env from './plugins/env';
import {OAuth} from '@nswdoe/doe-ui-core';
import {getConfig} from '@/api/utils';

import { IeBlocker } from '@nswdoe/doe-ui-core';

let renderComponent, renderProps;

if (IeBlocker.isIe()) {
  renderComponent = IeBlocker;
  renderProps = {
    props: {
      block: true,
      appName: "Decision tree quiz"
    }
  };
  initApp();
} else {
  renderComponent = App
  renderProps = {};

  Vue.config.productionTip = false;
  
  // Global event bus
  Vue.prototype.$eventHub = new Vue();
  
  Vue.use(env);

  if (process.env.NODE_ENV === 'production') {
    Vue.prototype.$env.init('/envmetadata')
    .then(function() {
      initApp();
    });
  } else {
    initApp();
  }
}

function initApp() {
  const authOptions = {
    router: router,
    pkce: true,
    secureApp: true,
    authoriseUri: getConfig('VUE_APP_OAUTH_URL'),
    tokenUri: getConfig('VUE_APP_OAUTH_TOKEN_URL'),
    logoutUri: getConfig('VUE_APP_OAUTH_LOGOUT_URL'),
    // authorise url params
    params: {
      client_id: getConfig('VUE_APP_OAUTH_CLIENT_ID'),
      redirect_uri: getConfig('VUE_APP_OAUTH_REDIRECT_URL'),
      scope: 'openid profile email'
    },
    disablePopupAuth: true
  };
  
  Vue.use(OAuth, authOptions);

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(renderComponent, renderProps)
  }).$mount('#app');
}
