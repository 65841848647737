import Vue from 'vue';
import quizApi from 'api/quiz';

const quizModule = {
  state: {
    quiz: {},
    currentQuestion: {},
    answeredQuestions: [],
    responses: []
  },
  getters: {
    quiz: state => state.quiz,
    currentQuestion: state => state.currentQuestion,
    // track answered questions for next and previous buttons
    answeredQuestions: state => state.answeredQuestions,
    responses: state => state.responses
  },
  mutations: {
    setQuiz(state, quiz) {
      state.quiz = quiz;
    },
    setCurrentQuestion(state, currentQuestion) {
      state.currentQuestion = currentQuestion;
    },
    addAnsweredQuestion(state, question) {
      state.answeredQuestions.push(question);
    },
    popAnsweredQuestion(state) {
      state.answeredQuestions.pop();
    },
    addResponse(state, {questionKey, option}) {
      const exists = state.responses.find(r => r.key === questionKey);
      if (exists) {
        Vue.set(exists, 'option', option);
      } else {
        state.responses.push({key: questionKey, option: option});
      }
    },
    initQuiz(state, quiz) {
      state.quiz = quiz;
      state.currentQuestion = quiz;
      state.answeredQuestions = [];
      state.responses = []
    }
  },
  actions: {
    async fetchQuiz({commit}, quizId) {
      // clear any previous quizzes
      commit('setQuiz', null);

      commit('setIsLoading', true);
      return await quizApi.fetchQuiz(quizId)
        .then((response) => {
          // init the quiz
          commit('initQuiz', response);
          commit('setIsLoading', false);
        })
        .catch(() => {
          commit('setIsLoading', false);
        });
    },
    nextQuestion({commit, state}, selectedOption) {      
      commit('addAnsweredQuestion', state.currentQuestion);
      commit('addResponse', {questionKey: state.currentQuestion.key, option: selectedOption});

      const nextQuestion = state.currentQuestion.children.find(c => c.key === selectedOption.leadsTo);
      commit('setCurrentQuestion', nextQuestion);
    },
    previousQuestion({commit, state}) {
      // set current to last answered question
      commit('setCurrentQuestion', state.answeredQuestions[state.answeredQuestions.length - 1]);
      
      // remove last answered question
      commit('popAnsweredQuestion');
    },
    restartQuiz({commit, state}) {
      commit('initQuiz', state.quiz);
    }
  }
};

export default quizModule;